// Generic imports
import React from "react"

// Other components imports
import Layout from "components/layout"
import ForgetPasswordComponent from "components/Account/ForgetPassword"
import SEO from "components/seo"

// Redux imports

// Static imports

// Utils imports

const ForgetPassword = () => (
  <Layout>
    <SEO title="Forget Password" />
    <ForgetPasswordComponent />
  </Layout>
)

export default ForgetPassword
