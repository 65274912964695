// Generic imports
import React, { useState } from "react"
import { toast } from "react-toastify"
import styled from "styled-components"

// Other components imports
import { AccountStyles } from "components/Account/index"
import Button from "components/Button"
import Image from "components/image"
import Input from "components/Reusable/Input"
import { useFormik } from "formik"

// Redux imports
import * as authApi from "../../../services/auth"

// Static imports

// Utils imports
import { handleAJAX } from "services/utils"

const StyledForgetPassword = styled.div`
  button {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 10px;
  }
`

export default function ForgetPasswordComponent() {
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit: async values => {
      setLoading(true)
      await handleAJAX(async () => {
        await authApi.forgetPassword(values.email)
        toast.success("Reset Password Request Sent!")
      })
      setLoading(false)
    },
  })

  return (
    <AccountStyles>
      <StyledForgetPassword>
        <div className="login-register">
          <div className="taman-logo">
            <Image imgName="taman-siswa-logo-full.png" alt="TamanSiswa" />
          </div>
          <form onSubmit={formik.handleSubmit}>
            <Input
              id="email"
              name="email"
              type="email"
              placeholder="Account Email"
              label="Email"
              required
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            <Button type="submit" disabled={loading}>
              Send Email
            </Button>
          </form>
        </div>
      </StyledForgetPassword>
    </AccountStyles>
  )
}
